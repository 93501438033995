export const ETH = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export const KNC = '0x4E470dc7321E84CA96FcAEDD0C8aBCebbAEB68C6'

export const ADX = '0x499990DB50b34687CDaFb2C8DaBaE4E99d6F38A7'

export const APPC = '0x2799f05B55d56be756Ca01Af40Bf7350787F48d4'

export const AST = '0xeF06F410C26a0fF87b3a43927459Cce99268a2eF'

export const BAT = '0xDb0040451F373949A4Be60dcd7b6B8D6E42658B6'

export const BBO = '0xa94758d328af7ef1815e73053e95b5F86588C16D'

export const BITX = '0x7a17267576318efb728bc4a0833e489a46ba138f'

export const BQX = '0x9504A86A881F63Da06302FB3639d4582022097DB'

export const COFI = '0xb91786188f8d4e35d6d67799e9f162587bf4da03'

export const DAI = '0xaD6D458402F60fD3Bd25163575031ACDce07538D'

export const ELF = '0x9Fcc27c7320703c43368cf1A4bf076402cd0D6B4'

export const ENG = '0x95cc8d8f29D0f7fcC425E8708893E759d1599c97'

export const GTO = '0xe55c607d58c53b2B06A8E38f67F4c0FcAeEd2c31'

export const IOST = '0x27db28a6C4ac3D82a08D490cfb746E6F02bC467C'

export const LINK = '0xb4f7332ed719Eb4839f091EDDB2A3bA309739521'

export const MANA = '0x72fd6C7C1397040A66F33C2ecC83A0F71Ee46D5c'

export const MOC = '0x1742c81075031b8f173d2327e3479d1fc3feaa76'

export const OMG = '0x4BFBa4a8F28755Cb2061c413459EE562c6B9c51b'

export const POWR = '0xa577731515303F0C0D00E236041855A5C4F114dC'

export const RCN = '0x99338aa9218C6C23AA9d8cc2f3EFaf29954ea26B'

export const RDN = '0x5422Ef695ED0B1213e2B953CFA877029637D9D26'

export const REQ = '0xb43D10BbE7222519Da899B72bF2c7f094b6F79D7'

export const SALT = '0xB47f1A9B121BA114d5e98722a8948e274d0F4042'

export const SNT = '0xbF5d8683b9BE6C43fcA607eb2a6f2626A18837a6'

export const STORM = '0x8FFf7De21de8ad9c510704407337542073FDC44b'

export const ZIL = '0xaD78AFbbE48bA7B670fbC54c65708cbc17450167'


export const ALLTokens = ['ETH', 'KNC', 'ADX', 'APPC', 'AST', 'BAT', 'BBO', 'BITX', 'BQX', 'COFI', 'DAI', 'ELF', 'ENG', 'GTO', 'IOST', 'LINK', 'MANA', 'MOC', 'OMG', 'POWR', 'RCN', 'RDN', 'REQ', 'SALT', 'SNT', 'STORM', 'ZIL']

export const KyberParametrs = ["0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff", "0x0000000000000000000000000000000000000000000000000000000000000001", "0x0000000000000000000000000000000000000000000000000000000000000000"]
