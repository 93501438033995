import React, { Component } from 'react'
import { Row, Col} from "react-bootstrap"

class Footer extends Component {
  render() {
    return (
      <Row className="footer">
      <Col>Since 2018: world's first live EthFi investments funds marketplace</Col>
      </Row>
    )
  }
}

export default Footer
